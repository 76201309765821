import React from 'react';

const Thankyou = () => {
	return (
		<div className="container">
			<h1>Thank you</h1>

			<p>
				Thank you for your order. Your pizza will be with you shortly.
			</p>
		</div>
	)
}

export default Thankyou;
